import React, { useState,useEffect, useRef } from 'react'
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IoIosArrowForward } from "react-icons/io";

//redux
import { useAppSelector } from '@/src/hooks/useSelector';

//hooks
import { useLogout } from '@/src/hooks/useLogout';

//icon
import { RiArrowDropDownLine } from 'react-icons/ri';

import { useAppDispatch } from '@/src/hooks/useDispatch';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';

//types
export type LanguageChoices= 'en' | 'jp'

interface UserDropdownMenuProps {
    Button?: JSX.Element;
}


export const UserDropdownMenu = ({
    Button,
}: UserDropdownMenuProps) => {

    //redux state
    const dispatch = useAppDispatch();

    const userData = useAppSelector(state => state.user).data
    const {is_instructor, email} = userData.user
    const {firstName, lastName} = userData.basic_profile

    //const dispatch = useAppDispatch();
    const router = useRouter();
    const logout = useLogout();

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language


    //logout handler
    const logoutHandler = () => {
        logout()
        //refresh page
        //router.reload();
    }

    // ref to use for outside click
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    // dropdown open 
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        // open dropdown
        setDropdownOpen(!isDropdownOpen);
    };


    // language nested dropdown
    const [isLanguageOpen, setLanguageOpen] = useState(false);
    const toggleLanguageDropdown = () => {
        setLanguageOpen(!isLanguageOpen);
    }

    // Handle language change and sync with router
    const handleLanguageChange = (newLanguage:LanguageChoices) => {
        if (newLanguage !== l) {
            //check if user logged or not
            let loggedIn = false
            if ('user' in userData && 'token' in userData.user) {
                loggedIn = true
            }
            // logged in: set to user settings in backend data
            if (loggedIn) {
                dispatch(updateLanguage({language: newLanguage}))
            // not logged in: only set state in redux
            } else {
                dispatch(setLanguage(newLanguage))
            }
            setDropdownOpen(false);
            setLanguageOpen(false);
        }
    };


    /**
     * CLICK OUTSIDE 
     */
    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
            setLanguageOpen(false);
        }
    };
    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
        window.removeEventListener('click', handleClickOutside);
        };
    }, []);



    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        lesson:{
            en:'Search Lessons',
            jp:'レッスン'
        },
        instructor:{
            en:'Search Instructors',
            jp:'インストラクター'
        },
        bookings:{ en:'Bookings', jp:'予約' },
        following:{ en:'Following', jp:'フォロー中' },
        messages:{ en:'Messages', jp:'メッセージ' },
        settings:{ en:'Settings', jp:'設定' },
        language:{ en:'Language', jp:'言語' },
        teach:{ en:"I want to teach", jp:'教えたい方' },
        switch_instructor:{ en:'Switch to Instructor', jp:'インストラクターページ' },
        log_out:{ en:'Log out', jp:'ログアウト' },
    }

    const links = [
        { href: '/user/reservations', label: text['bookings'][l] },
        { href: '/user/following', label: text['following'][l] },
        { href: '/user/messages', label: text['messages'][l] },
        { href: '/user/settings/personal', label: text['settings'][l] },
    ]


    return (
        <div
            ref={dropdownRef}
            className="relative inline-block text-left
        ">
            <button 
                type='button'
                onClick={toggleDropdown}
                className='flex items-center justify-center'
            >
                {   Button ? (
                    <div className='flex items-center'>
                        {Button}
                    </div>
                ) : (
                    <div className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <RiArrowDropDownLine className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                )
                }
            </button>

            <div className={`
                z-10 absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700
                ${isDropdownOpen ? 'opacity-100' : 'opacity-0 hidden'}
            `}>
                <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                    <div>{firstName} {lastName}</div>
                    <div className="font-medium truncate">{email}</div>
                </div>

                <ul className="py-1 text-sm text-gray-700 w-full md:hidden">
                    <li className=''>
                        <Link
                            href='/search-lessons'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['lesson'][l]}
                        </Link>
                    </li>
                    <li className=''>
                        <Link
                            href='/search-instructors'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['instructor'][l]}
                        </Link>
                    </li>
                </ul>

                <ul className="py-1">
                    {   links.map(link => (
                        <li key={link.label}>
                            <Link
                                href={link.href}
                                className={`
                                    text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                    block px-4 py-2 text-sm`}
                                >{link.label}
                            </Link>
                        </li>
                    ))}
                </ul>

                <ul className="py-1 text-sm text-gray-700">
                    <li className='relative'>
                        <button 
                            onClick={toggleLanguageDropdown}
                            type="button" 
                            className="
                                flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 
                            ">
                            {text['language'][l]}
                            <IoIosArrowForward size={18}/>
                        </button>
                        <ul className={`
                            z-10 bg-white absolute -left-28 top-0 divide-y divide-gray-100 rounded-lg shadow w-28 text-gray-700 
                            ${isLanguageOpen ? 'opacity-100' : 'opacity-0'}
                        `}>
                            <li>
                                <p 
                                    onClick={l!='en' ? () => handleLanguageChange('en') : undefined}
                                    className={`
                                        text-sm block px-4 py-2 hover:bg-gray-100 
                                        ${l!='en' ? 'cursor-pointer' : 'font-bold'}
                                    `}>English
                                </p>
                            </li>
                            <li>
                                <p 
                                    onClick={l!='jp' ? () => handleLanguageChange('jp') : undefined}
                                    className={`
                                        text-sm block px-4 py-2 hover:bg-gray-100
                                        ${l!='jp' ? 'cursor-pointer' : 'font-bold'}
                                    `}>日本語
                                </p>
                            </li>
                        </ul>
                    </li>
                    {   is_instructor ? (
                        <li>
                            <Link
                                href='/instructor/dashboard/home'
                                className={`
                                    text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                    block px-4 py-2 text-sm`}
                                >{text['switch_instructor'][l]}
                            </Link>
                        </li>
                    ): (
                        <li>
                            <Link
                                href='/join-ambition/instructor'
                                className={`
                                    text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                    block px-4 py-2 text-sm`}
                                >{text['teach'][l]}
                            </Link>
                        </li>
                    )}
                    <li>
                        <div
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm
                            `}
                            onClick={logoutHandler}
                            >{text['log_out'][l]}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
