import {useState, useEffect} from 'react'
import { useRouter } from 'next/router';

import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';

import axios from 'axios'
import { useAppSelector } from '../hooks/useSelector';



interface NotificationCardProps {
    notifObject: {
        photo?: string;
        message_en: string;
        message_jp: string;
        timestamp: Date;
        href?: string;
        is_read: boolean;
    }
    onClick?: () => void;
}


export const NotificationCard = ({
    notifObject,
    onClick,
}:NotificationCardProps) => {

    const router = useRouter();
    const userData = useAppSelector(state => state.user).data
    const {token} = userData.user

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language


    const timeAgoString = formatDistanceToNow(notifObject.timestamp, { addSuffix: true, locale: enUS });

    const handleClick = () => {
        if (onClick) {onClick()}
        //set as read && go to page
        if (notifObject.href) {
            router.push(notifObject.href)
        }
    }


    return (
        <div 
            className={`w-full h-[85px] p-3 rounded-lg flex flex-row gap-2 cursor-pointer hover:bg-gray-100`}
            onClick={() => handleClick()}
        >
            <div className='h-full aspect-square rounded-full bg-gray-200'>
            </div>
            <div className='w-full flex flex-col gap-0 justify-center'>
                <p className={`
                    line-clamp-2 text-sm
                    ${notifObject.is_read ? 'text-gray-500' : ''}
                    `}>{    l=='jp' ?
                        notifObject.message_jp :
                        notifObject.message_en
                    }
                </p>
                <p className={`
                     text-sm font-semibold
                    ${notifObject.is_read ? 'text-gray-500' : 'text-blue-600'}
                    `}>{timeAgoString}
                </p>
            </div>

            <div className='ml-auto flex items-center w-[12px]'>
                {   !notifObject.is_read && (
                    <div className='w-full aspect-square rounded-full bg-blue-600'>
                    </div>
                )}
            </div>
        </div>
    )
}