import React, { useState,useEffect, useRef } from 'react'
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IoIosArrowForward } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { Button } from '@/src/atoms-new/Button';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';


//hooks
import { useLogout } from '@/src/hooks/useLogout';

//icon
import { RiArrowDropDownLine } from 'react-icons/ri';

import { useAppDispatch } from '@/src/hooks/useDispatch';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';

//types
export type LanguageChoices= 'en' | 'jp'

interface GuestDropdownMenuProps {}


export const GuestDropdownMenu = ({
}: GuestDropdownMenuProps) => {

    //redux state
    const dispatch = useAppDispatch();

    const userData = useAppSelector(state => state.user).data

    //const dispatch = useAppDispatch();
    const router = useRouter();
    const logout = useLogout();

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language




    // ref to use for outside click
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    // dropdown open 
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = (e:React.SyntheticEvent) => {
        // e.preventDefault();
        e.stopPropagation();
        // open dropdown
        setDropdownOpen(!isDropdownOpen);
    };


    // language nested dropdown
    const [isLanguageOpen, setLanguageOpen] = useState(false);
    const toggleLanguageDropdown = () => {
        setLanguageOpen(!isLanguageOpen);
    }

    // Handle language change and sync with router
    const handleLanguageChange = (newLanguage:LanguageChoices) => {
        if (newLanguage !== l) {
            //check if user logged or not
            let loggedIn = false
            if ('user' in userData && 'token' in userData.user) {
                loggedIn = true
            }
            // logged in: set to user settings in backend data
            if (loggedIn) {
                dispatch(updateLanguage({language: newLanguage}))
            // not logged in: only set state in redux
            } else {
                dispatch(setLanguage(newLanguage))
            }
            setDropdownOpen(false);
            setLanguageOpen(false);
        }
    };


    /**
     * CLICK OUTSIDE 
     */
    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
            setLanguageOpen(false);
        }
    };
    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
        window.removeEventListener('click', handleClickOutside);
        };
    }, []);



    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        lesson:{
            en:'Search Lessons',
            jp:'レッスン'
        },
        instructor:{
            en:'Search Instructors',
            jp:'インストラクター'
        },
        language:{
            en:'Language',
            jp:'言語'
        },
        
        become_instructor:{ en:"Become an Instructor", jp:'教えたい方' },
        
        login:{
            en:'Login',
            jp:'ログイン'
        },
        signup:{
            en:'Create Account',
            jp:'新規登録'
        }
    }


    return (
        <div
            ref={dropdownRef}
            className="relative inline-block text-left
        ">
            {   isDropdownOpen ? (
                <IoClose 
                    size={24} 
                    className="text-gray-800 cursor-pointer" 
                    onClick={toggleDropdown}
                />
            ) : (
                <IoMenu 
                    size={24} 
                    className="text-gray-800 cursor-pointer" 
                    onClick={toggleDropdown}
                />
            )}

            <div className={`
                z-10 absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700
                ${isDropdownOpen ? 'opacity-100' : 'opacity-0 hidden'}
            `}>
                <ul className="py-1 text-sm text-gray-700 w-full">
                    <li className='md:hidden'>
                        <Link
                            href='/search-lessons'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['lesson'][l]}
                        </Link>
                    </li>
                    <li className='md:hidden'>
                        <Link
                            href='/search-instructors'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['instructor'][l]}
                        </Link>
                    </li>
                    <li className='relative'>
                        <button 
                            onClick={toggleLanguageDropdown}
                            type="button" 
                            className="
                                flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 
                            ">
                            Language
                            <IoIosArrowForward size={18}/>
                        </button>
                        <ul className={`
                            z-10 bg-white absolute -left-28 top-0 divide-y divide-gray-100 rounded-lg shadow w-28 text-gray-700 
                            ${isLanguageOpen ? 'opacity-100' : 'opacity-0'}
                        `}>
                            <li>
                                <p 
                                    onClick={l!='en' ? () => handleLanguageChange('en') : undefined}
                                    className={`
                                        text-sm block px-4 py-2 hover:bg-gray-100 
                                        ${l!='en' ? 'cursor-pointer' : 'font-bold'}
                                    `}>English
                                </p>
                            </li>
                            <li>
                                <p 
                                    onClick={l!='jp' ? () => handleLanguageChange('jp') : undefined}
                                    className={`
                                        text-sm block px-4 py-2 hover:bg-gray-100
                                        ${l!='jp' ? 'cursor-pointer' : 'font-bold'}
                                    `}>日本語
                                </p>
                            </li>
                        </ul>
                    </li>

                </ul>

                <ul className="py-1 text-sm text-gray-700 w-full">
                    

                    <li>
                        <Link
                            href='/join-ambition/instructor'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['become_instructor'][l]}
                        </Link>
                    </li>
                    <li>
                        <Link
                            href='/auth/login'
                            className={`
                                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                                block px-4 py-2 text-sm`}
                            >{text['login'][l]}
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Button 
                            color='bg-prime-user hover:bg-orange-600'
                            textColor='text-white'
                            borderColor=''
                            className='w-full'
                            size='md'
                            onClick={() => router.push('/auth/signup')}
                            ><p className=' text-sm md:text-[16px]'>{text['signup'][l]}</p>
                        </Button>
                    </li>
                </ul>
            </div>
        </div>
    )
}
