


import { useAppDispatch } from '@/src/hooks/useDispatch';
import { userLogout } from '@/store/slices/userSlice';
import { resetBookmark } from '@/store/slices/bookmarkSlice';
import { resetInstructorSlice } from '@/store/slices/instructor-slice/instructorSlice';



export const useLogout = () => {
    const dispatch = useAppDispatch();

    const logout = async () => {
        dispatch(userLogout())
        dispatch(resetBookmark())
        dispatch(resetInstructorSlice())
    }
    return logout
}

