import React, {useState, useEffect} from 'react'
import { BouncingLoader } from './loader/BouncingLoader';

interface ButtonProps {
  /** colors */
  color: string; // bg-orange-500 hover:bg-orange-500 / [#C3C9C9]
  textColor: string;
  borderColor: string;

  /* styling variations */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rounded?: 'none' | 'full' | 'md' | 'lg'

  /** functionality & state */
  isSubmit?:boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled? : boolean;
  loading?: boolean;

  /* etc */
  className? : string;
  children: string | JSX.Element[] | JSX.Element;
}

export const Button = ({
  color = 'bg-orange-500 hover:bg-orange-700',
  textColor = 'text-white',
  borderColor = '',

  size = 'lg', 
  rounded = 'full',

  isSubmit = false,
  onClick,
  disabled = false, 
  loading = false,

  className,
  children = 'button'
}: ButtonProps) => {

  // Button State Variables
  const [isActive, setIsActive] = useState(false);


  /** SIZE */
  const [buttonSize, setButtonSize] = useState('px-5 py-2.5 text-sm font-medium')
  useEffect(() => {
    if (size==='xs') { setButtonSize('px-3 py-2 text-xs font-medium')} 
    else if (size==='sm') { setButtonSize('px-3 py-2 text-sm font-medium') } 
    else if (size==='md') { setButtonSize('px-4 py-3 text-[16px] font-medium') } 
    else if (size==='lg') { setButtonSize('px-5 py-4 text-[16px] font-medium') } 
    else if (size==='xl') { setButtonSize('px-0 py-0 text-base font-medium') }
  },[size])


  /** Click handling */
  const clickHandler = (e:React.SyntheticEvent) => {
    setIsActive(!isActive)
    if (onClick) {
      onClick(e);
    }
  }

  const cursor = disabled || loading ? 'cursor-auto' : 'cursor-pointer'

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={`flex items-center justify-center 

        rounded-${rounded}
        
        ${disabled ? 'bg-gray-400 text-gray-700' : `${color} ${textColor} ${borderColor}`}

        ${buttonSize}
        ${cursor}
        ${className}
      `}
      // style={combinedStyles}
      disabled={disabled || loading}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      onClick={clickHandler}
    >
      {loading ? (
        <BouncingLoader className='mt-3'/>
      ) : (
        children
      )}
    </button>
  )
}
